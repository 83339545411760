import './bootstrap';
import '../css/app.css';
import Lara from '@/PrimeVue/presets/lara';
import { library as faLibrary } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createInertiaApp } from '@inertiajs/vue3';
import * as Sentry from '@sentry/vue';
import Highcharts from 'highcharts';
import more from 'highcharts/highcharts-more';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import { createApp, h } from 'vue';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import { AppFAIcons } from './Config/Icons';

faLibrary.add(...AppFAIcons);

more(Highcharts);
Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
    numericSymbols: ['K', 'M', 'G', 'T', 'P', 'E'],
  },
  accessibility: {
    enabled: false,
  },
});

const appName = import.meta.env.VITE_APP_NAME || 'FranchiseSEE';

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) });

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
      environment: import.meta.env.APP_ENV,
    });

    app
      .use(plugin)
      .use(PrimeVue, {
        unstyled: true,
        pt: Lara,
        ripple: true,
      })
      .use(ToastService)
      .use(ZiggyVue)
      .directive('tooltip', Tooltip)
      .component('font-awesome-icon', FontAwesomeIcon)
      .mount(el);

    return app;
  },
  progress: {
    color: '#4B5563',
  },
});
